import { sendGtmEvent } from 'packages/util/gtm';
import { handleActions } from 'redux-actions';
import actions from '../actions/checkout';

export const name = 'checkout';

const initialState = {
  loading: false,
  loaded: false,
};

const reducerMap = {
  [actions.update]: (state, { payload }) => {
    const finalState = {
      ...state,
      ...payload,
      loading: false,
      loaded: true,
    };

    finalState.shippingRequired = Boolean(
      (finalState.items || []).find(i => i.__typename != 'VirtualCartItem'),
    );
    return finalState;
  },
  [actions.loading.start]: state => {
    return { ...state, loading: true };
  },
  [actions.loading.end]: state => {
    return { ...state, loading: false };
  },
  [actions.reset]: (payload) => ({ ...initialState }),
  // [actions.billingAddress.submit]: state => ({
  //   ...state,
  //   billingAddressError: null,
  //   isSubmitting: true,
  // }),
  // [actions.billingAddress.accept]: (state, { payload }) => {
  //   return {
  //     ...state,
  //     isSubmitting: false,
  //     billing_address: {
  //       ...payload,
  //       street: [...payload.street],
  //     },
  //   };
  // },
  // [actions.billingAddress.reject]: (state, { payload }) => {
  //   return {
  //     ...state,
  //     billingAddressError: payload,
  //     isSubmitting: false,
  //   };
  // },
  // [actions.getShippingMethods.receive]: (state, { payload, error }) => {
  //   if (error) {
  //     return state;
  //   }

  //   return {
  //     ...state,
  //     availableShippingMethods: payload.map(method => ({
  //       // ...method,
  //       code: method.carrier_code,
  //       title: method.carrier_title,
  //     })),
  //   };
  // },
  // [actions.shippingAddress.submit]: state => ({
  //   ...state,
  //   isSubmitting: true,
  //   shippingAddressError: null,
  // }),
  // [actions.shippingAddress.accept]: (state, { payload }) => {
  //   return {
  //     ...state,
  //     isSubmitting: false,
  //     shippingAddress: {
  //       ...state.shippingAddress,
  //       ...payload,
  //       street: [...payload.street],
  //     },
  //   };
  // },
};

export default handleActions(reducerMap, initialState);
