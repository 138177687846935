export type ImportGlobEagerOutput = Record<string, () => Promise<any>>;
export function createPageRoutes(
  pages: ImportGlobEagerOutput,
  topLevelPath = "*"
): any[] {
  const topLevelPrefix = topLevelPath.replace("*", "").replace(/\/$/, "");
  const routes = Object.keys(pages).map((key) => {
    let path = key
      .replace("./routes", "")
      .replace(/\.server\.(t|j)sx?$/, "")
      .replace(/\/index$/i, "/")
      .replace(/\b[A-Z]/, (firstLetter) => firstLetter.toLowerCase())
      .replace(/\[\.{3}.+\]/, "*")
      .replace(/\[(\w+?)\]/g, (_match, param: string) => `:${param}`);

    if (path.endsWith("/") && path !== "/")
      path = path.substring(0, path.length - 1);


    return {
      path: topLevelPrefix + path,
      componentLoader: pages[key],
    };
  });

  /**
   * Place static paths BEFORE dynamic paths to grant priority.
   */
  return [
    ...routes.filter((route) => !route.path.includes(":")),
    ...routes.filter((route) => route.path.includes(":")),
  ];
}
