import { useCheckoutContext } from 'packages/framework/context/checkout';
import { useMemo } from 'react';

export const getCheckoutMessage = ({ items }) => {
  const errorMap = items.reduce((acc, item) => {
    return (item?.errors || []).reduce((acc, { code, message }) => {
      if (code == 'ITEM_QTY') {
        acc[code] = {
          code,
          message:
            'Some items in your cart have quantity related errors. Please review order summary and correct it to proceed.',
        };
      } else {
        acc[code] = {
          code,
          message,
        };
      }
      return acc;
    }, acc);
  }, {});

  return (Object.values(errorMap) || []) as Array<{
    code: string;
    message: string;
  }>;
};

const useCheckoutMessage = ({ items }) => {
  return useMemo(() => {
    return getCheckoutMessage({ items });
  }, [items]) as Array<{ code: string; message: string }>;
};

export default useCheckoutMessage;
