import useIsomorphicLayoutEffect from '../util/useIsomorphicLayoutEffect';

/**
 * A [React Hook]{@link https://reactjs.org/docs/hooks-intro.html} that sets
 * an attribute on the document element indicating that scrolling should be
 * locked. This is performed with a layout effect (before paint).
 *
 * @kind function
 *
 * @param {Boolean} locked Whether scrolling should be locked.
 */
const lockBackground = (locked) => {
  if (locked) {
    const offsetY = window.pageYOffset;
    document.body.style.top = `${-offsetY}px`;
    document.body.classList.add('js-lock-position');
  } else {
    const offsetY = Math.abs(
      parseInt(String(document.body.style.top || 0), 10),
    );
    document.body.classList.remove('js-lock-position');
    document.body.style.removeProperty('top');
    window.scrollTo(0, offsetY || 0);
  }
};
export const useScrollLock = locked => {
  useIsomorphicLayoutEffect(() => {
    if (!globalThis.document) return;

    document.documentElement.dataset.scrollLock = locked || '';
    lockBackground(locked)
  }, [locked]);
};
