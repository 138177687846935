import { getSize } from 'packages/framework/hooks/useWindowSize';

export const gtm = gtmData => {
  console.log('__GTM PUSH__', gtmData.event, gtmData);
  try {
    window['google_tag_manager'][import.meta.env.VITE_GTM_ID].dataLayer.reset();
  } catch (e) {}
  try {
    window['dataLayer'].push(gtmData);
  } catch (e) {}
};

export const sendGtmEvent = (event, obj) => {
  const { isMobile } = getSize();
  const device = isMobile ? 'mobile' : 'desktop';
  return gtm({
    event,
    ...obj,
    device,
  });
};
function getCartItemsV2(cart) {
  return cart.items.map(
    ({
      product: { name, categories, sku },
      prices: {
        row_total: { value, currency },
      },
      quantity,
      configurable_options,
      configured_variant,
    }) => {
      // Send unit price
      let variant = configurable_options
        ? configurable_options.map(o => o.value_label).join(',')
        : undefined;

      let category =
        categories && categories[0] ? categories[0].name : undefined;
      let variantPrice =
        configured_variant?.price_range.minimum_price.regular_price.value;
      let retail_price = value / quantity;
      let discount = variantPrice - retail_price;
      return {
        item_id: sku,
        // item_list_id: 'ABC',
        item_name: name,
        // item_list_name: 'Ceiling Fan',
        item_brand: 'Atomberg', // static
        item_category: category,
        item_variant: variant,
        price: variantPrice,
        retail_price,
        quantity,
        discount: discount,
        currency: 'INR', // static
      };
    },
  );
}
export function getCartItems(cart) {
  return cart.items.map(
    ({
      product: { name, categories, sku },
      prices: {
        row_total: { value, currency },
      },
      quantity,
      configurable_options,
    }) => {
      // Send unit price
      let variant = configurable_options
        ? configurable_options.map(o => o.value_label).join(',')
        : undefined;

      let category =
        categories && categories[0] ? categories[0].name : undefined;

      return {
        name: name,
        price: value / quantity,
        sku,
        id: sku,
        variant,
        quantity,
        category,
      };
    },
  );
}

export const sendCartLoaded = cart => {
  try {
    let shipping_addresses = cart.shipping_addresses || [];
    let shipping_address = shipping_addresses[0] || {};

    gtm({
      event: 'cartLoaded',
      cart: {
        items: getCartItems(cart),
        address: shipping_address,
      },
    });
  } catch (e) {}
};

export const sendUserLoaded = user => {
  try {
    if (!user) {
      gtm({
        event: 'userLoaded',
        user: {
          loggedInStatus: false,
        },
      });
    } else {
      const { email, firstname, lastname } = user;
      gtm({
        event: 'userLoaded',
        user: {
          loggedInStatus: true,
          email,
          firstname,
          lastname,
        },
      });
    }
  } catch (e) {}
};
export const sendPageView = (page, rest = {}) => {
  try {
    //@ts-ignore
    window.prerenderReady = true
    setTimeout(() => {
      gtm({
        event: 'pageView',
        page,
        title: document.title,
        ...rest,
      });
    }, 100);
  } catch (e) {}
};
export const sendProductItemSelect = (product, category, price, variant) => {
  try {
    const { name, sku, categories } = product;
    gtm({
      event: 'select_item',
      pagePath: window.location.href,
      pageType: 'product',
      pageTitle: name,
      ecommerce: {
        items: [
          {
            item_id: sku,
            item_name: name,
            item_brand: 'Atomberg',
            item_category: category,
            price: price.regular_price.value,
            retail_price: price.final_price.value,
            discount: price.discount.amount_off,
            currency: 'INR', // static
            quantity: 1,
            item_variant: variant,
          },
        ],
      },
    });
  } catch (e) {}
};
export const sendProductDetailImpressions = (product, price, variant) => {
  try {
    const { name, sku, categories } = product;
    let category = categories && categories[0] ? categories[0].name : undefined;
    gtm({
      event: 'view_item',
      pagePath: window.location.href,
      pageType: 'product',
      pageTitle: name,
      items: [
        {
          id: sku,
        },
      ],
      ecommerce: {
        // Digital RIO
        items: [
          {
            item_id: sku,
            // item_list_id: 'ABC',
            item_name: name,
            // item_list_name: 'Ceiling Fan',
            item_brand: 'Atomberg',
            item_category: category,
            price: price.regular_price.value,
            retail_price: price.final_price.value,
            discount: price.discount.amount_off,
            currency: 'INR', // static
            quantity: 1,
            item_variant: variant,
          },
        ],
        detail: {
          // actionField: { list: 'Apparel Gallery' }, // 'detail' actions have an optional list property.
          products: [
            {
              name,
              id: sku,
              sku,
              price: price ? price.value : undefined,
              variant,
              category,
            },
          ],
        },
      },
    });
  } catch (e) {}
};
export const sendProdutListImpression = (category, products) => {
  try {
    // sendPageView(window.location.href)
    gtm({
      event: 'Category View',
      category,
      item_count: products.length,
    });
    gtm({
      event: 'view_item_list',
      ecommerce: {
        currency: 'INR', // Local currency is optional.
        impressions: products.map((product, index) => ({
          name: product.name,
          id: product.sku,
          sku: product.sku,
          price: product.price_range.minimum_price.final_price.value,
          category,
          position: +index + 1,
        })),
        items: products.map((product, index) => {
          let price = product.price_range.minimum_price;
          return {
            // item_list_id: 'ABC',
            // item_list_name: 'Ceiling Fan',
            item_id: product.sku,
            item_name: product.name,
            item_brand: 'Atomberg',
            item_category: category,
            price: price.regular_price.value,
            retail_price: price.final_price.value,
            discount: price.discount.amount_off,
            currency: 'INR', // static
            quantity: 1,
          };
        }),
      },
    });
  } catch (e) {}
};

export const findCartItem = (cartItems, cartItemInput) => {
  let { selected_options, sku, id } = cartItemInput;
  return cartItems.find(item => {
    let found = false;

    if (item.product.sku == sku) {
      found = true;
      if (item.configurable_options) {
        for (let {
          configurable_product_option_value_uid,
        } of item.configurable_options) {
          if (
            !selected_options.includes(configurable_product_option_value_uid)
          ) {
            found = false;
          }
        }
      }
    } else if (item.uid == id) {
      found = true;
    }
    return found;
  });
};

export let cartEventMap = {
  add: 'add_to_cart',
  remove: 'remove_from_cart',
};

export const sendCartEvents = (
  cartItems,
  cartItemInput,
  action: 'add' | 'remove',
  removedItem = null,
) => {
  try {
    let { quantity } = cartItemInput;

    let cartItem = findCartItem(cartItems, cartItemInput);

    if (!cartItem && !removedItem) return null;

    let {
      product: { name, categories, sku },
      prices: {
        price: { value, currency },
      },
      configurable_options,
      configured_variant,
    } = cartItem || removedItem;

    let variant = configurable_options
      ? configurable_options.map(o => o.value_label).join(',')
      : undefined;
    let variantPrice =
      configured_variant?.price_range.minimum_price.regular_price.value;
    let discount = variantPrice - value;

    let category = categories && categories[0] ? categories[0].name : undefined;

    gtm({
      event: cartEventMap[action],
      ecommerce: {
        currency: currency,
        value: value * quantity,
        items: [
          {
            id: sku,
            item_id: sku,
            item_name: name,
            item_brand: 'Atomberg',
            item_category: category,
            item_variant: variant,
            price: variantPrice,
            retail_price: value,
            discount: discount,
            currency: 'INR',
            quantity,
          },
        ],
        [action]: {
          products: [
            {
              name,
              price: value,
              id: sku,
              sku,
              variant,
              quantity,
              category,
            },
          ],
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendCheckoutEventV2Data = (cart, additional = {}) => {
  const shippingAddress = cart.shipping_addresses && cart.shipping_addresses[0];

  const value = cart.prices.grand_total.value;

  const discount = (cart.prices.discounts || []).reduce((a, d) => {
    return a + d.amount.value;
  }, 0);

  let ecommerce: any = {
    value,
    currency: 'INR',
    discount,
    quanitity: cart.total_quantity,
    items: getCartItemsV2(cart),
    ...additional,
  };

  if (cart.email) {
    ecommerce.email_id = cart.email;
  }

  if (shippingAddress) {
    const { city, firstname, lastname, postcode, region, street, telephone } =
      shippingAddress;
    ecommerce.first_name = firstname;
    ecommerce.last_name = lastname;
    ecommerce.phone_number = telephone;
    ecommerce.address = street.join(' ');
    ecommerce.zip_code = postcode;
    ecommerce.city = city;
    ecommerce.region = region.label;
  }
  if (cart.applied_coupons) {
    ecommerce.coupon = cart.applied_coupons[0]?.code;
  }
  return ecommerce;
};

export const sendCheckoutEventV2 = (event, cart, additional = {}) => {
  try {
    gtm({
      event,
      ecommerce: sendCheckoutEventV2Data(cart, additional),
    });
  } catch (e) {
    console.log(e);
  }
};
export const sendSheckoutEvent = (event, cart, action) => {
  try {
    const checkout: any = {
      actionField: action, //{ step, option },
      // products: getCartItems(cart),
    };
    if (cart) {
      checkout.products = getCartItems(cart);
    }
    gtm({
      event: 'checkout',
      ecommerce: {
        checkout,
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const purchaseEvent = async (cart, order_number) => {
  try {
    let tax =
      cart.prices.cgst_charge?.value +
      cart.prices.igst_charge?.value +
      cart.prices.sgst_charge?.value;

    let revenue = cart.prices.grand_total.value;

    let shippingAddress = (cart.shipping_addresses || [])[0] || {};
    let userId = await sha256(`91${shippingAddress?.telephone}`);

    let shipping_amount =
      shippingAddress?.selected_shipping_method?.amount.value;
    gtm({
      event: 'purchase',
      userId,
      transaction_id: order_number,
      order_value: revenue,
      order_id: cart.id,
      enhanced_conversion_data: {
        email: cart.email,
      },
      ecommerce: {
        ...sendCheckoutEventV2Data(cart, {
          transaction_id: order_number,
        }),
        purchase: {
          actionField: {
            userId,
            id: order_number, // Transaction ID. Required for purchases and refunds.
            affiliation: 'Online Store',
            revenue,
            tax: tax,
            shipping: shipping_amount,
            coupon: (cart.applied_coupons || []).map(c => c.code).join('|'),
          },
          products: getCartItems(cart),
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const lastTouchAttribution = () => {
  let trackingParamList = [
    'gclid',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
  ];
  var sP = new URLSearchParams(window.location.search);
  var params = trackingParamList.reduce((m, key) => {
    if (sP.has(key)) {
      m[key] = sP.get(key);
    }
    return m;
  }, {});
  if (Object.keys(params).length > 0) {
    trackingParamList.forEach(key => {
      localStorage.removeItem(`lt_${key}`);
      if (params[key]) {
        localStorage.setItem(`lt_${key}`, params[key]);
      }
    });
  }
};

export async function sha256(message) {
  if (!message) return '';
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}
