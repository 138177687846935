const initialState = {
  toasts: new Map(),
};

export default function toast(prevState = initialState, action: any = {}) {
  const { type, payload } = action;

  switch (type) {
    case 'toast_add': {
      const nextToasts = new Map(prevState.toasts);
      const prevToast = nextToasts.get(payload.id);

      const isDuplicate = !!prevToast;
      let timestamp = payload.timestamp;
      if (isDuplicate) {
        // If this is a _new_ duplicate toast we need to clear the
        // previous timeout to prevent premature removal.
        globalThis.clearTimeout(prevToast.removalTimeoutId);

        // And to retain chronological order of addition, keep the
        // original timestamp.
        timestamp = prevToast.timestamp;
      }

      nextToasts.set(payload.id, {
        ...payload,
        timestamp,
        isDuplicate,
      });

      return {
        ...prevState,
        toasts: nextToasts,
      };
    }
    case 'toast_remove': {
      const nextToasts = new Map(prevState.toasts);

      const prevToast = nextToasts.get(payload.id);
      if (prevToast) {
        globalThis.clearTimeout(prevToast.removalTimeoutId);
      }

      nextToasts.delete(payload.id);

      return {
        ...prevState,
        toasts: nextToasts,
      };
    }
    default:
      return prevState;
  }
}
