import { AnalyticsEvents } from 'packages/framework/util/logger/constants';
import { generateClientId } from 'packages/framework/util/logger/generateClientId';

export const logGA4Event = async (eventName, eventParams) => {
  try {
    let storageClientId = localStorage.getItem(AnalyticsEvents?.GA4_CLIENT_ID);
    const clientId = storageClientId || generateClientId();

    if (!storageClientId)
      localStorage.setItem(AnalyticsEvents?.GA4_CLIENT_ID, clientId);

    const payload = {
      clientId: clientId,
      eventName: eventName,
      params: eventParams,
    };
    const response = await fetch(`/api/sendGAEvent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error('Failed to send event to GA4');
    }

    console.log('Event sent to GA4:', eventName, eventParams);
  } catch (error) {
    console.error('Error sending event to GA4:', error);
  }
};
