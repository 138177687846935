import { ApolloQueryResult } from '@apollo/client';
import useCheckoutMessage, {
  getCheckoutMessage,
} from 'packages/framework/hooks/CheckoutPage/CheckoutMessage/useCheckoutMessage';
import {
  sendCheckoutEventV2,
  sendCheckoutEventV2Data,
} from 'packages/util/gtm';
import { useMemo } from 'react';
import BrowserPersistence from '../../../util/simplePersistence';
import { closeDrawer } from '../app';
import actions from './actions';
import { logGA4Event } from 'packages/framework/util/logger/triggerGAEvent';
import { AnalyticsEvents } from 'packages/framework/util/logger/constants';

const storage = new BrowserPersistence();

export const beginCheckout = ({ getCartDetails }) =>
  async function thunk(dispatch, getState) {
    const { cart } = getState();
    const cartId = cart.cartId;
    dispatch(actions.loading.start());
    try {
      const { data, error, errors } = (await getCartDetails({
        variables: {
          cartId,
        },
        fetchPolicy: 'no-cache',
      })) as ApolloQueryResult<any>;

      sendCheckoutEventV2(
        AnalyticsEvents?.BEGIN_CHECKOUT_EVENT_NAME,
        data.cart,
      );
      dispatch(updateCheckoutData(data.cart));
      logGA4Event(
        AnalyticsEvents?.BEGIN_CHECKOUT_EVENT_NAME,
        JSON.stringify(sendCheckoutEventV2Data(data.cart)),
      );
    } catch (e) {
      // Add Error Message
      dispatch(actions.reset());
      logGA4Event(
        AnalyticsEvents?.BEGIN_CHECKOUT_ERROR_EVENT_NAME,
        JSON.stringify(e),
      );
    }
  };

export const updateCheckoutData = cart =>
  async function thunk(dispatch) {
    const errors = getCheckoutMessage({
      items: cart?.items || [],
    });

    const hasError = errors.length > 0;
    const errorMessage = hasError ? errors[0].message : null;

    dispatch(actions.update({ ...cart, errors, hasError, errorMessage }));
  };

export const cancelCheckout = () =>
  async function thunk(dispatch) {
    dispatch(actions.reset());
  };

export const resetCheckout = () =>
  async function thunk(dispatch) {
    await dispatch(closeDrawer());
    dispatch(actions.reset());
  };
