import React, { createContext, useContext, useState } from 'react';
import { useConfigs } from './talons/useConfigs';
const AmXsearchContext = createContext(null);
const { Provider } = AmXsearchContext;

const AmXsearchProvider = (props: any) => {
  const [singleProduct, setSingleProduct] = useState({ enabled: false });
  const { children } = props;
 
  const contextValue = {
    storeConfig: STORE_CONFIG,
    singleProduct,
    setSingleProduct,
  };
  return <Provider value={contextValue}>{children}</Provider>;
};

export default AmXsearchProvider;

export const useAmXsearchContext = () => useContext(AmXsearchContext);
