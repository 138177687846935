import { FC, useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
export { default as HeadProvider } from './headProvider';
import { Helmet } from 'react-helmet-async';

Helmet['defaultProps'].defer = false;

export const Link = props => {
  const { children, ...tagProps } = props;
  return (
    <Helmet>
      <link {...tagProps}>{children}</link>
    </Helmet>
  );
};

export const Meta = props => {
  const { children, ...tagProps } = props;
  return (
    <Helmet>
      <meta {...tagProps}>{children}</meta>
    </Helmet>
  );
};

export const Style = props => {
  const { children, ...tagProps } = props;
  return (
    <Helmet>
      <style {...tagProps}>{children}</style>
    </Helmet>
  );
};

export const Title = props => {
  const { children, ...tagProps } = props;
  return (
    <Helmet>
      <title {...tagProps}>{children}</title>
    </Helmet>
  );
};

const STORE_NAME_QUERY = gql`
  query getStoreName {
    # eslint-disable-next-line @graphql-eslint/require-id-when-available
    storeConfig {
      store_code
      store_name
      base_url
      locale
    }
  }
`;

interface StoreSeoProps {
  title: string;
  meta_title: string;
  meta_description: string;
  canonical_url: string;
  image: string;
  schema: any;
  schemaOrg?: any;
  extraMeta?: Array<{ property: string; content: string }>;
  productFaqSchema?: any;
  carouselSchema?: any;
}

const baseUrl = import.meta.env.VITE_FRONTEND_URL || 'https://atomberg.com';

export const StoreSeo: FC<StoreSeoProps> = props => {
  const { title, meta_title, meta_description, canonical_url, image, schema } =
    props;

  const extraMeta = props.extraMeta || [];

  const locale = STORE_CONFIG?.locale || DEFAULT_LOCALE;
  const canonicalUrl = baseUrl + `/${canonical_url}`;
  const storeName = STORE_CONFIG?.store_name
    ? STORE_CONFIG?.store_name
    : STORE_NAME;
  let titleText = meta_title || title || storeName;
  return (
    <>
      <Helmet
        title={titleText}
        link={[
          {
            rel: 'canonical',
            href: canonicalUrl,
          },
          { rel: 'alternate', href: canonicalUrl, hrefLang: 'x-default' },
        ]}
        meta={[
          {
            name: 'description',
            content: meta_description,
          },
          {
            property: 'og:title',
            content: titleText,
          },
          {
            property: 'og:url',
            content: canonicalUrl,
          },
          {
            property: 'og:description',
            content: meta_description,
          },
          {
            property: 'og:image',
            content: image,
          },
          {
            property: 'og:locale',
            content: locale,
          },
          ...extraMeta,
        ]}
      >
        <script
          className="structured-data-list"
          type="application/ld+json"
          defer
        >
          {schema}
        </script>
        {props.schemaOrg && (
          <script
            className="structured-data-list-schema"
            type="application/ld+json"
            defer
          >
            {props.schemaOrg}
          </script>
        )}
        {props?.productFaqSchema && (
          <script
            className="structured-data-list"
            type="application/ld+json"
            defer
          >
            {props.productFaqSchema}
          </script>
        )}
        {props?.carouselSchema && (
          <script
            className="structured-data-list"
            type="application/ld+json"
            defer
          >
            {props.carouselSchema}
          </script>
        )}
      </Helmet>
    </>
  );
};
export const StoreTitle = props => {
  const { children, ...tagProps } = props;

  const storeName = STORE_CONFIG?.store_name
    ? STORE_CONFIG?.store_name
    : STORE_NAME;

  let titleText;
  if (children) {
    titleText = `${children} - ${storeName}`;
  } else {
    titleText = storeName;
  }

  return (
    <Helmet>
      <title {...tagProps}>{titleText}</title>
    </Helmet>
  );
};
